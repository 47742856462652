// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import Header from './components/Header';
import Hero from './components/Hero';
import CookieNotification from './components/CookieNotification';
import './i18n';
import Cookies from 'js-cookie';

// Lazy load the non-visible components
const Services = React.lazy(() => import('./components/Services'));
const Work = React.lazy(() => import('./components/Work'));
const LilIntro = React.lazy(() => import('./components/LilIntro'));
const Footer = React.lazy(() => import('./components/Footer'));
const Article = React.lazy(() => import('./components/Article'));
const SocialRedirect = React.lazy(() => import('./components/SocialRedirect'));
const NotFound = React.lazy(() => import('./components/NotFound')); // Import NotFound component

const App = () => {
  const [theme, setTheme] = useState(
    Cookies.get('theme') ||
      (document.body.classList.contains('dark-theme') ? 'dark' : 'light')
  );
  const [showLandscapeWarning, setShowLandscapeWarning] = useState(false);
  const [showHero, setShowHero] = useState(true);

  useEffect(() => {
    document.body.className = theme === 'dark' ? 'dark-theme' : 'light-theme';
    Cookies.set('theme', theme);
  }, [theme]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (height <= 768 && height < width && width <= 1024) {
        setShowLandscapeWarning(true);
      } else {
        setShowLandscapeWarning(false);
      }

      if (width <= 768) {
        setShowHero(false);
      } else {
        setShowHero(true);
      }
    };

    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <Router>
      <div className="App">
        {showLandscapeWarning && (
          <div className="landscape-warning">
            Please rotate your device to portrait mode
          </div>
        )}
        <Header theme={theme} toggleTheme={toggleTheme} />

        <Routes>
          <Route
            path="/"
            element={
              <>
                {showHero && <Hero theme={theme} />}
                <React.Suspense fallback={<div>Loading section...</div>}>
                  <LilIntro theme={theme} />
                </React.Suspense>
                <React.Suspense fallback={<div>Loading section...</div>}>
                  <Services theme={theme} />
                </React.Suspense>
                <React.Suspense fallback={<div>Loading section...</div>}>
                  <Work theme={theme} />
                </React.Suspense>
              </>
            }
          />
          <Route
            path="/blogpost/:articleId"
            element={
              <React.Suspense fallback={<div>Loading article...</div>}>
                <Article theme={theme} />
              </React.Suspense>
            }
          />
          {/* Route for Social Redirect */}
          <Route
            path="/redirect"
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <SocialRedirect theme={theme} />
              </React.Suspense>
            }
          />
          {/* Catch-all route for 404 Not Found */}
          <Route
            path="*"
            element={
              <React.Suspense fallback={<div>Loading...</div>}>
                <NotFound theme={theme} />
              </React.Suspense>
            }
          />
        </Routes>

        <React.Suspense fallback={<div>Loading footer...</div>}>
          <Footer theme={theme} />
        </React.Suspense>

        <CookieNotification />
      </div>
    </Router>
  );
};

export default App;
