// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie'; // Keep the import now that we are using Cookies
import LanguageDetector from 'i18next-browser-languagedetector';

// Import common translations for all languages
import en from './i18n/en/common.json';
import ru from './i18n/ru/common.json';

// Import article translations
import article1_en from './i18n/en/articles/article1.json';
import article1_ru from './i18n/ru/articles/article1.json';
// Import other articles as needed

const resources = {
  en: {
    common: en,
    'articles/article1': article1_en,
    // Add other namespaces or articles as needed
  },
  ru: {
    common: ru,
    'articles/article1': article1_ru,
    // Add other namespaces or articles as needed
  },
  // Include other languages similarly if needed
};

i18n
  // .use(Backend) // Remove Backend since we're loading resources directly
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    ns: ['common', 'articles/article1'], // Specify all namespaces being used
    defaultNS: 'common', // Set default namespace
    detection: {
      order: ['cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false, // Disable Suspense for simplicity
    },
  });

// Store the user's language preference in a cookie for 2 years
i18n.on('languageChanged', (lng) => {
  Cookies.set('i18next', lng, { expires: 730 }); // 730 days = 2 years
});

export default i18n;
